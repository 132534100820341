var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.clientList
    ? _c(
        "div",
        [
          _c(
            "b-card-header",
            { staticClass: "pb-50" },
            [
              _c("h4", [_vm._v(" Clients List ")]),
              _c(
                "b-form-checkbox",
                {
                  attrs: { name: "check-button", switch: "", inline: "" },
                  model: {
                    value: _vm.showFilters,
                    callback: function($$v) {
                      _vm.showFilters = $$v
                    },
                    expression: "showFilters"
                  }
                },
                [_c("h5", [_vm._v(" Filters ")])]
              )
            ],
            1
          ),
          _vm.showFilters
            ? _c(
                "b-card-body",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "mb-md-1 mb-1",
                          attrs: { cols: "12", md: "9" }
                        },
                        [
                          _c("label", [_vm._v("Client")]),
                          _c("v-select", {
                            staticClass: "w-100",
                            attrs: {
                              dir: _vm.$store.state.appConfig.isRTL
                                ? "rtl"
                                : "ltr",
                              value: _vm.clientFilter,
                              options: _vm.clientList,
                              reduce: function(val) {
                                return val.value
                              }
                            },
                            on: {
                              input: function(val) {
                                return _vm.handleSelectClient(val)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.siteOptions !== null
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "mb-md-1 mb-1",
                              attrs: { cols: "12", md: "9" }
                            },
                            [
                              _c("label", [_vm._v("Site")]),
                              _c("v-select", {
                                staticClass: "w-100",
                                attrs: {
                                  dir: _vm.$store.state.appConfig.isRTL
                                    ? "rtl"
                                    : "ltr",
                                  value: _vm.siteFilter,
                                  options: _vm.siteOptions,
                                  reduce: function(val) {
                                    return val.value
                                  }
                                },
                                on: {
                                  input: function(val) {
                                    return _vm.handleSelectClientSite(val)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }